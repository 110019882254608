import { Outlet, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import User from "../User";
import {useEffect} from "react";

const Layout = () => {
    const location = useLocation();

    useEffect(() => {
        // Il componente si ri-renderizza ogni volta che il percorso cambia
        //Necessario perchè si.
    }, [location.pathname]);

    //Mostra il nome utente solo se è stato già settato
    return (
        <>
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="#">The cocky's game</Navbar.Brand>
                <Navbar.Toggle/>
                {
                    User.isUserSet() &&
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            Ciaoh {User.getUser()}
                        </Navbar.Text>
                    </Navbar.Collapse>
                }
            </Container>
        </Navbar>
		<br/>
        <Container>
            <Outlet/>
        </Container>
        </>
    )
};

export default Layout;