import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from "react";
import API from "../API";
import Utils from "../Utils";

const Home = () => {
    //Lista parole
    const [wordList, setWordList] = useState([]);
	
	//parola corrente
	const [word, setWord] = useState('Sukas');
	
    useEffect(() => {
        const words = Utils.shuffleArray(API.getWords());
        setWordList(words);
    }, []);


    //Richiamata su click su OK
    const handleNext = () => {
		const randomElement = wordList[Math.floor(Math.random() * wordList.length)];
		setWord(Utils.capitalizeFirstLetter(randomElement));
    }

    return (
        <>
            <h2 className={"text-center"}>WELCOME TO THE</h2>
            <h1 className={"text-center"}><b>COCKY'S GAMES</b>!!!</h1>
            <br/>
            <p>Questo è solo un primo piccolo esperimento, un giochino messo a disposizione dalla Davide&Felipe&#174; S.P.C. (Società Per Cocky/Cagasbura).</p>
            <p>Vi proponiamo qui un Felipe Senior rivisitato per funziona con le parole del gruppo Sukates (un Sukates Senior quindi).</p>
            <p>A differenza del vecchio Felipe Senior qui le parole le dovete mimare e se parlate mentre mimate siete calabresi.</p>
            <br/>
            <p>Basta cliccare il tasto per generare una nuova parola randomica.</p>
            <Form.Group className='form-group'>
				<br/><br/>
                <h1 className={"text-center"}>{word}</h1>
				<br/><br/>
                <Button className={"mx-auto d-block"} variant='primary' type='submit' onClick={() => handleNext()}>Genera</Button>
                <br/><br/>
            </Form.Group>
        </>
    );
};

export default Home;