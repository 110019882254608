const shuffleArray = (array) => array
    .map(value => ({value, sort: Math.random()}))
    .sort((a, b) => a.sort - b.sort)
    .map(({value}) => value)

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Utils = {
    shuffleArray, capitalizeFirstLetter
}

export default Utils;