import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
//import Home from './pages/Home';
// import InsDomande from './pages/InsDomande';
// import WaitForOthers from './pages/WaitForOthers';
// import RispDomande from './pages/RispDomande';
import FelipeSenior from './pages/FelipeSenior';

export default function App() {
  //Definisco le varie routes
  return (
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<Layout/>}>
            <Route index element={<FelipeSenior/>}/>
            <Route path="*" element={<NoPage/>}/>
        </Route>
    </Routes>
  </BrowserRouter>
  );
  
// <Route index element={<Home/>}/>
// <Route path="step1" element={<InsDomande/>}/>
// <Route path="step2" element={<WaitForOthers/>}/>
// <Route path="step3" element={<RispDomande/>}/>
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
