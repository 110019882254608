import Cookies from "js-cookie";

const setUser = (user) => Cookies.set("user", user);

const getUser = () => Cookies.get("user");

const isUserSet = () => !!Cookies.get("user");

const User = {
    setUser, getUser, isUserSet
}

export default User;