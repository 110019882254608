const words = [
    "vagnoni",
    "ristorante",
    "morire",
    "volta",
    "intra",
    "merda",
    "niente",
    "subito",
    "cazzo",
    "qualcosa",
    "ricordo",
    "periodo",
    "telefono",
    "https",
    "faccio",
    "stati",
    "bisogno",
    "quannu",
    "parlare",
    "italiano",
    "dovrebbe",
    "essere",
    "tutto",
    "chiaro",
    "mancherebbe",
    "allora",
    "state",
    "vabb\u00e8",
    "questo",
    "neanche",
    "effettivamente",
    "grazie",
    "stesso",
    "noooo",
    "questa",
    "immagine",
    "sento",
    "proprio",
    "famiglia",
    "arrivato",
    "tranqui",
    "numero",
    "perch\u00e9",
    "secondo",
    "invece",
    "benissimo",
    "infatti",
    "fastidio",
    "gloria",
    "basta",
    "certa",
    "lorenzo",
    "stiamo",
    "coglioni",
    "tanto",
    "rotto",
    "farmi",
    "signora",
    "nemmeno",
    "voglio",
    "vedere",
    "intanto",
    "cristo",
    "quanto",
    "brutto",
    "santo",
    "nella",
    "macchina",
    "senti",
    "contro",
    "viola",
    "messo",
    "emmanuel",
    "moriu",
    "grande",
    "sembra",
    "bianco",
    "squacquerone",
    "preso",
    "giulia",
    "prima",
    "della",
    "ovvio",
    "comunque",
    "sopra",
    "scusate",
    "senza",
    "coglione",
    "piace",
    "porto",
    "messaggio",
    "stato",
    "modificato",
    "purtroppo",
    "troppo",
    "quando",
    "piedi",
    "morta",
    "proposito",
    "ultimo",
    "concerto",
    "fatto",
    "suonare",
    "ragazzo",
    "scritto",
    "fatta",
    "guarda",
    "prosciutto",
    "crudo",
    "dovrei",
    "almeno",
    "avrei",
    "paura",
    "semplicemente",
    "facendo",
    "carne",
    "gruppo",
    "bella",
    "chiama",
    "chiamo",
    "ormai",
    "mancu",
    "immagini",
    "mettere",
    "funziona",
    "capisco",
    "stavo",
    "farlo",
    "finale",
    "avevo",
    "iniziato",
    "molto",
    "altro",
    "qualche",
    "letteralmente",
    "prendo",
    "amici",
    "dovuto",
    "morto",
    "diverso",
    "clara",
    "viene",
    "figlio",
    "perfetto",
    "entrambi",
    "quindi",
    "nenzi",
    "scusa",
    "eliminato",
    "insomma",
    "mattina",
    "scoperto",
    "laurea",
    "abbastanza",
    "strano",
    "stile",
    "video",
    "mentre",
    "magari",
    "davvero",
    "trovato",
    "andare",
    "tranquillo",
    "tutta",
    "uscito",
    "meglio",
    "esatto",
    "detto",
    "voglia",
    "quoto",
    "chiede",
    "avere",
    "festa",
    "glielo",
    "risposto",
    "volevo",
    "momento",
    "felipe",
    "appena",
    "esiste",
    "google",
    "giuro",
    "potrebbe",
    "sarebbe",
    "letto",
    "storia",
    "agosto",
    "parlando",
    "marco",
    "fanno",
    "prendere",
    "usciamo",
    "dimmi",
    "capelli",
    "cavalli",
    "bello",
    "mamma",
    "scrivere",
    "andrea",
    "palesemente",
    "santi",
    "bravo",
    "oltre",
    "fratello",
    "fuori",
    "nuova",
    "posso",
    "morti",
    "cognome",
    "insieme",
    "giorno",
    "faceva",
    "tutti",
    "sordi",
    "chiui",
    "unica",
    "menomale",
    "perch\u00e8",
    "ragazzi",
    "raccomando",
    "forse",
    "totale",
    "senso",
    "stessa",
    "sempre",
    "simone",
    "porca",
    "persona",
    "stava",
    "stasera",
    "vicino",
    "sotto",
    "zitto",
    "ultima",
    "uscita",
    "andato",
    "altra",
    "entra",
    "ancora",
    "occhiali",
    "fazzu",
    "computer",
    "mondo",
    "soldi",
    "buona",
    "dormire",
    "honest",
    "stata",
    "questi",
    "tutte",
    "stessi",
    "gusti",
    "onesto",
    "mannaggia",
    "vuole",
    "dirlo",
    "madre",
    "quasi",
    "sicuro",
    "minuti",
    "adoro",
    "nsomma",
    "vanno",
    "oddio",
    "aveva",
    "tante",
    "dipende",
    "testa",
    "sapere",
    "cazzi",
    "domanda",
    "credo",
    "risposta",
    "inizio",
    "sapevo",
    "posto",
    "unico",
    "pensato",
    "consiglio",
    "trovo",
    "chiesto",
    "resto",
    "sandali",
    "dovresti",
    "realt\u00e0",
    "lavoro",
    "probabile",
    "capito",
    "onestamente",
    "felice",
    "ridere",
    "vorrei",
    "capire",
    "usato",
    "spesso",
    "estate",
    "cagare",
    "giusto",
    "aperto",
    "porco",
    "senn\u00f2",
    "tornare",
    "faccia",
    "bellissima",
    "conosco",
    "versione",
    "adesso",
    "porno",
    "davide",
    "pesce",
    "mezza",
    "padre",
    "manda",
    "direttamente",
    "provo",
    "favore",
    "whatsapp",
    "piacere",
    "auguri",
    "vista",
    "belli",
    "quest",
    "difficile",
    "stavamo",
    "botto",
    "manco",
    "pensare",
    "farti",
    "regalo",
    "piacciono",
    "problemi",
    "libro",
    "visto",
    "maglietta",
    "spero",
    "volete",
    "parte",
    "punto",
    "metto",
    "piccola",
    "peccato",
    "altri",
    "migliore",
    "settimana",
    "fatti",
    "oppure",
    "inizia",
    "certo",
    "tranquilla",
    "matteo",
    "probabilmente",
    "effetti",
    "musica",
    "nostra",
    "guardare",
    "andiamo",
    "gallipoli",
    "persone",
    "delle",
    "erano",
    "viaggio",
    "gente",
    "dentro",
    "nuovo",
    "qualcuno",
    "super",
    "livello",
    "tardi",
    "direi",
    "tempo",
    "stanno",
    "leggere",
    "ragazza",
    "spettacolo",
    "sbagliato",
    "solito",
    "volte",
    "fosse",
    "assurdo",
    "carit\u00e0",
    "comprare",
    "giorni",
    "pagare",
    "figurati",
    "arriva",
    "godos",
    "facciamo",
    "buono",
    "dispiace",
    "chiedi",
    "lascia",
    "stare",
    "maledetto",
    "carlo",
    "ovviamente",
    "voleva",
    "palese",
    "entrare",
    "cazzu",
    "piano",
    "venire",
    "nessuno",
    "eleonora",
    "possiamo",
    "passare",
    "praticamente",
    "sogno",
    "parlato",
    "cambiare",
    "degli",
    "scherzo",
    "vieni",
    "quale",
    "corso",
    "davanti",
    "pagato",
    "sicuramente",
    "prego",
    "penso",
    "madonna",
    "seconda",
    "minchia",
    "scrive",
    "uguale",
    "esattamente",
    "pensavo",
    "fortuna",
    "chiss\u00e0",
    "italia",
    "finch\u00e9",
    "chiedere",
    "problema",
    "orario",
    "saranno",
    "rispetto",
    "sondaggio",
    "metti",
    "negozio",
    "passato",
    "diventato",
    "onesti",
    "droga",
    "scrivi",
    "amico",
    "vediamo",
    "dicevo",
    "pensa",
    "serve",
    "sorella",
    "conto",
    "compa",
    "perso",
    "quante",
    "lecce",
    "pranzo",
    "dobbiamo",
    "tocca",
    "minimo",
    "accanto",
    "pasta",
    "tranne",
    "casarano",
    "succede",
    "ragione",
    "vagno",
    "dicendo",
    "aggiu",
    "matino",
    "nello",
    "scarpe",
    "parla",
    "messaggi",
    "vaffanculo",
    "giornata",
    "presto",
    "fiata",
    "capisce",
    "importante",
    "soprattutto",
    "pensando",
    "immagina",
    "casino",
    "andata",
    "audio",
    "mangiare",
    "mezzo",
    "diventa",
    "passa",
    "andate",
    "impossibile",
    "onesta",
    "mammamia",
    "guida",
    "potete",
    "massimo",
    "successo",
    "manca",
    "colpa",
    "cercando",
    "partito",
    "quell",
    "arabella",
    "sukas",
    "verso",
    "belle",
    "uscire",
    "sinceramente",
    "chiamato",
    "serata",
    "studio",
    "arrivo",
    "matonna",
    "maggiu",
    "lasciare",
    "ricordi",
    "arrivare",
    "bagno",
    "libero",
    "prendi",
    "domani",
    "notte",
    "sacco",
    "discussione",
    "lavorare",
    "chiedo",
    "domenica",
    "usare",
    "appunto",
    "generale",
    "sentire",
    "treno",
    "padova",
    "errori",
    "frase",
    "sabato",
    "forza",
    "prendendo",
    "schifo",
    "caldo",
    "sbura",
    "avuto",
    "diventare",
    "brava",
    "normale",
    "caff\u00e8",
    "circa",
    "devono",
    "durante",
    "riesco",
    "secondi",
    "cujune",
    "fattu",
    "parole",
    "mario",
    "collega",
    "toccu",
    "entro",
    "nelle",
    "mando",
    "finita",
    "finito",
    "finalmente",
    "torno",
    "cristiani",
    "tuttu",
    "vaffanculu",
    "lista",
    "sacciu",
    "parto",
    "passo",
    "costa",
    "contrario",
    "progetto",
    "altre",
    "pomeriggio",
    "bellissimo",
    "chill",
    "studiare",
    "esame",
    "primo",
    "cambia",
    "trovare",
    "prova",
    "mette",
    "prende",
    "ferie",
    "stipendio",
    "silvia",
    "fallo",
    "profilo",
    "sborro",
    "settembre",
    "pezzo",
    "tantissimo",
    "esperienza",
    "cerca",
    "instagram",
    "azienda",
    "venuto",
    "andando",
    "pronto",
    "chitarra",
    "chiamata",
    "palle",
    "queste",
    "internet",
    "genere",
    "ansia",
    "fanne",
    "parti",
    "codice",
    "sarebbero",
    "possibile",
    "immagino",
    "spazio",
    "motivo",
    "diciamo",
    "teoria",
    "pensi",
    "inglese",
    "simili",
    "quanti",
    "vengo",
    "tinne",
    "trovi",
    "porta",
    "pesche",
    "serio",
    "peggio",
    "potrei",
    "provare",
    "provato",
    "abbia",
    "giuda",
    "cristiano",
    "dietro",
    "maledetti",
    "sentito",
    "dicono",
    "stronzata",
    "monopoly",
    "prossima",
    "danno",
    "avanti",
    "addhu",
    "maestro",
    "fanculo",
    "canzone",
    "froci",
    "gioco",
    "risponde",
    "clienti",
    "mille",
    "doveva",
    "acqua",
    "accordo",
    "diceva",
    "gratis",
    "mandato",
    "possono",
    "colore",
    "online",
    "attimo",
    "iddhu",
    "finire",
    "scherzi",
    "youtube",
    "cujuni",
    "elodie",
    "spotify",
    "terapia",
    "pizza",
    "conviene",
    "cristiana",
    "nulla",
    "discorso",
    "mannaggiah",
    "deciso",
    "finisce",
    "perfavore",
    "fibra",
    "sorry",
    "frocio",
    "watch",
    "poche",
    "perci\u00f2",
    "lavori",
    "sborra",
    "cocky",
    "sesso",
    "trova",
    "settimane",
    "playlist",
    "rimane",
    "luglio"
]

export default words;