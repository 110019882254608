import words from "./data";

const baseURL = 'https://cockysgame.altervista.org/api';

async function getProfiles() {
    const resp = await fetch(`${baseURL}/users.php`);

    if (resp.ok) {
        return await resp.json();
    } else {
        throw new Error("Can't fetch profiles")
    }
}

async function saveQuestions(questions, user) {
    let QuestionsAsAString = "user=" + user;
    for (let i = 0; i < questions.length; i += 1) {
        QuestionsAsAString += "&question" + i + "=" + questions[i].domanda
            + "-@-" + questions[i].risposte[0]
            + "-@-" + questions[i].risposte[1]
            + "-@-" + questions[i].risposte[2]
            + "-@-" + questions[i].risposte[3];
    }
    console.log(QuestionsAsAString);
    const resp = await fetch(`${baseURL}/saveQuestions.php?${QuestionsAsAString}`);

    return resp.ok;
}

async function setPoints(user, points) {
    const params = "user=" + user + "&points=" + points;

    const resp = await fetch(`${baseURL}/setPoints.php?${params}`);

    return resp.ok;
}

async function getPoints(user) {
    const params = "user=" + user;

    const resp = await fetch(`${baseURL}/getPoints.php?${params}`);

    if (resp.ok) {
        return await resp.json();
    } else {
        throw new Error("Can't fetch points")
    }
}

function getWords() {
    return words;
}

const API = {getProfiles, saveQuestions, setPoints, getPoints, getWords};
export default API;